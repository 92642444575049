import { useCallback } from 'react';
import { DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';
import {
  getPrinterHealth,
  getPrinterLastSeenTime,
  getSecurityAssessmentResult,
  isPrinterDisconnected,
  isLegacy,
  hasPrintOnTheGo
} from 'src/utils/utils';
import { useDeviceApiAsDeviceCache } from './useDeviceApiAsDeviceCache';
import { getFeatureFlag } from 'src/utils/featureFlags';

export default function useDevicesApiCall({ authProvider, stack }) {
  const { getDevices } = useDeviceApiAsDeviceCache({
    authProvider,
    stack
  });

  const apiCall = useCallback(async () => {
    let response = [];
    const useNewIphLogic = await getFeatureFlag({ key: 'useNewIphLogic' });
    const useNewSyncNoteLogic = await getFeatureFlag({
      key: 'useNewSyncNoteLogic'
    });
    const useDeviceCache = await getFeatureFlag({ key: 'useDeviceCache' });
    if (useDeviceCache) {
      const client = new DeviceCacheApiClient(stack, authProvider);
      const options = {
        params: {
          limit: 200
        }
      };
      response = await client.list(options);
    } else {
      response = await getDevices();
    }
    return response.data?.map?.((device) => ({
      ...device,
      isLegacy: isLegacy(device),
      hasPrintOnTheGo: hasPrintOnTheGo(device),
      printerHealth: getPrinterHealth(device),
      printerIsDisconnected: isPrinterDisconnected(device),
      printerLastSeenTime: getPrinterLastSeenTime(device),
      securityAssessmentResult: getSecurityAssessmentResult(device),
      useNewIphLogic,
      useNewSyncNoteLogic
    }));
  }, [stack, authProvider, getDevices]);

  return useApiCall({ apiCall });
}
