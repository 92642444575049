import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PATHS from 'src/constants/paths';
import {
  DeviceStatusContainer,
  PrinterCard,
  PrinterGroupSupplies,
  PrinterMeta,
  PrinterGroupImage,
  PrinterCardFooter,
  PrinterGroupText,
  SubscribeLink,
  ProgressBox
} from './styles';
import Supplies from 'src/components/Supplies';
import FirmwareUpdate from 'src/components/FirmwareUpdate';
import { useRootContext, useI18n } from '@jarvis/react-portal-addons';
import { ProgressIndicator } from '@veneer/core';
import Images from 'src/assets/images';
import PrinterDetails from 'src/components/PrinterDetails';
import DeviceStatus from 'src/components/DeviceStatus/DeviceStatus';
import PrinterOptions from 'src/components/PrinterOptions';
import { STATUS } from 'src/utils/statusDictionary';
import useDeviceEligibilityApiCall from 'src/hooks/useDeviceEligibilityApiCall';
import useSecondChanceEligibilityApiCall from 'src/hooks/useSecondChanceEligibilityApiCall';
import SecondOffer from 'src/components/SecondOffer';
import { publishHyperLinkClickedEvent } from 'src/utils/analytics';
import useDeviceSolutionsApiCall from 'src/hooks/useDeviceSolutionsApiCall';

const Printer = ({
  device,
  userInfo,
  handleOpenTransferDeviceModal,
  handleOpenRemoveDeviceModal,
  accountSolutions
}) => {
  const { t } = useI18n();
  const { analytics, authProvider, stack, shell } = useRootContext();
  const [printerImage, setPrinterImage] = useState(Images.genericPrinter);

  const { roleCategory: userRoleCategory = '', type: tenantType = '' } =
    shell?.tenantHandlerInterface?.getTenantData() || {};

  const isTenantOwner = useMemo(
    () =>
      (tenantType.toLowerCase() === 'personal' &&
        userRoleCategory.toLowerCase() === 'admin') ||
      (tenantType.toLowerCase() === 'wfh-employee' &&
        userRoleCategory.toLowerCase() === 'user'),
    [userRoleCategory, tenantType]
  );

  useEffect(() => {
    if (device.images?.length > 0) {
      setPrinterImage(device.images[device.images.length - 1].url);
    } else {
      setPrinterImage(Images.genericPrinter);
    }
  }, [device.images]);

  const deviceSolutions = useDeviceSolutionsApiCall({
    authProvider,
    stack,
    deviceId: device.deviceId,
    accountId: device.ownership?.accountId
  });

  const secondChance = useSecondChanceEligibilityApiCall({
    authProvider,
    stack,
    deviceId: device.deviceId,
    platformIdentifier: device.identity?.platformIdentifier
  });

  const eligibility = useDeviceEligibilityApiCall({
    authProvider,
    stack,
    userId: userInfo?.user?.resourceId,
    deviceId: device.deviceId,
    orgGroupId: device.ownership?.accountId
  });

  const showSubscribeLink = useMemo(
    () =>
      deviceSolutions?.data?.subscriptionStatus ===
        STATUS.SUBSCRIPTION.ELIGIBLE &&
      deviceSolutions?.data?.subscriptionUpdatePlan ===
        STATUS.UPDATE_PLAN.UNKNOWN,
    [
      deviceSolutions?.data?.subscriptionStatus,
      deviceSolutions?.data?.subscriptionUpdatePlan
    ]
  );

  const showSubscriptionStatus = useMemo(
    () =>
      deviceSolutions?.data?.subscriptionStatus ===
      STATUS.SUBSCRIPTION.SUBSCRIBED,
    [deviceSolutions?.data?.subscriptionStatus]
  );

  const showPrinterGroupSupplies = useMemo(
    () => showSubscribeLink || showSubscriptionStatus || !device.isLegacy,
    [showSubscribeLink, showSubscriptionStatus, device.isLegacy]
  );

  const printerProperties =
    `connection=${device.status?.connectionState}` +
    `&security=${device.securityAssessmentResult}` +
    `&status=${device.printerHealth}`;

  const analyticsMetadata = useMemo(
    () => ({
      associatedDeviceUuid: device?.identity?.deviceUuid,
      associatedDeviceProductNumber: device?.identity?.makeAndModel?.number
    }),
    [device?.identity?.deviceUuid, device?.identity?.makeAndModel?.number]
  );

  const showLoader = deviceSolutions.isFetching || eligibility.isFetching;

  if (showLoader) {
    return (
      <ProgressBox>
        <ProgressIndicator
          appearance="circular"
          behavior="indeterminate"
        />
      </ProgressBox>
    );
  }

  return (
    <>
      <PrinterCard data-testid="printer-info-card-pp">
        <PrinterMeta withSupplies={showPrinterGroupSupplies}>
          <PrinterGroupImage>
            <img
              src={printerImage}
              alt={device.identity?.makeAndModel?.series}
              onError={() => setPrinterImage(Images.genericPrinter)}
            />
          </PrinterGroupImage>
          <PrinterGroupText>
            <p
              className="subtitle-large"
              data-testid="printer-name-pp"
            >
              {device.identity?.makeAndModel.name.replace(/_/g, ' ')}
            </p>
            {device.status?.connectionState && (
              <DeviceStatusContainer>
                <DeviceStatus
                  status={device.status.connectionState}
                  group={STATUS.GROUP.connection}
                  data-testid="internet-connection-status-pp"
                />
              </DeviceStatusContainer>
            )}
            {device.securityAssessmentResult && (
              <DeviceStatusContainer>
                <DeviceStatus
                  status={device.securityAssessmentResult}
                  group={STATUS.GROUP.security}
                  data-testid="security-monitoring-status-pp"
                />
              </DeviceStatusContainer>
            )}
            {device.printerHealth && (
              <DeviceStatusContainer>
                <DeviceStatus
                  status={device.printerHealth}
                  group={STATUS.GROUP.health}
                  data-testid="printing-state-status-pp"
                />
              </DeviceStatusContainer>
            )}
          </PrinterGroupText>
        </PrinterMeta>
        {showPrinterGroupSupplies && (
          <PrinterGroupSupplies>
            <Supplies device={device} />
            {showSubscribeLink && (
              <SubscribeLink
                href={PATHS.INSTANT_INK}
                className="caption"
                onClick={() => {
                  publishHyperLinkClickedEvent(
                    analytics,
                    'EnrollHpInstantInk',
                    device?.identity?.makeAndModel?.name,
                    undefined,
                    analyticsMetadata,
                    printerProperties
                  );
                }}
                data-testid="enroll-II-pp"
              >
                {t('myPrinters.subscribe')}
              </SubscribeLink>
            )}
            {showSubscriptionStatus && (
              <DeviceStatus
                status={STATUS.SUBSCRIPTION.SUBSCRIBED}
                group={STATUS.GROUP.subscription}
                data-testid="ii-enrollment-status-printers-pp"
              />
            )}
          </PrinterGroupSupplies>
        )}
      </PrinterCard>
      {secondChance.data?.eligible && (
        <SecondOffer
          device={device}
          expires={secondChance.data?.expires}
        />
      )}
      <FirmwareUpdate
        device={device}
        subscription={deviceSolutions?.data?.subscriptionStatus}
        subscriptionUpdatePlan={deviceSolutions?.data?.subscriptionUpdatePlan}
      />

      {isTenantOwner && (
        <PrinterOptions
          device={device}
          eligibility={eligibility.data}
          handleOpenTransferDeviceModal={handleOpenTransferDeviceModal}
          handleOpenRemoveDeviceModal={handleOpenRemoveDeviceModal}
          accountSolutions={accountSolutions}
          deviceSolutions={deviceSolutions?.data}
          data-testid="printer-options-pp"
        />
      )}
      <PrinterDetails
        device={device}
        subscription={deviceSolutions?.data?.subscriptionStatus}
      />
      {device.printerLastSeenTime && (
        <PrinterCardFooter
          className="caption"
          data-testid="printer-last-updated-pp"
        >
          {device.printerIsDisconnected && (
            <span>{t('myPrinters.printerIsOffline')}</span>
          )}
          {t('myPrinters.lastUpdatedTime', {
            timestamp: device.printerLastSeenTime,
            interpolation: { escapeValue: false }
          })}
        </PrinterCardFooter>
      )}
    </>
  );
};

Printer.propTypes = {
  device: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string
      })
    ),
    deviceId: PropTypes.string,
    identity: PropTypes.shape({
      makeAndModel: PropTypes.shape({
        name: PropTypes.string,
        series: PropTypes.string
      }),
      platformIdentifier: PropTypes.string
    }),
    ownership: PropTypes.shape({
      accountId: PropTypes.string
    }),
    status: PropTypes.shape({
      connectionState: PropTypes.string
    }),
    securityAssessmentResult: PropTypes.string,
    printerHealth: PropTypes.string,
    printerLastSeenTime: PropTypes.string,
    printerIsDisconnected: PropTypes.bool
  }),
  userInfo: PropTypes.shape({
    user: PropTypes.shape({
      resourceId: PropTypes.string
    }),
    userTenantDetail: PropTypes.shape({
      roleCategory: PropTypes.string.isRequired
    })
  }),
  handleOpenTransferDeviceModal: PropTypes.func.isRequired,
  handleOpenRemoveDeviceModal: PropTypes.func.isRequired,
  accountInfo: PropTypes.shape({
    type: PropTypes.string.isRequired
  }),
  programInfos: PropTypes.shape({
    hasPrintOnTheGo: PropTypes.bool
  })
};

export default Printer;
