import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import PrinterAccordion from 'src/shared/PrinterAccordion';
import FirmwareUpdateSettings from './FirmwareUpdateSettings';
import useGetFwUpdCfgApiCall from '../../hooks/useGetFwUpdCfgApiCall';
import { RootContext, useI18n, ErrorWidget } from '@jarvis/react-portal-addons';

const FirmwareUpdate = ({ device, subscription, subscriptionUpdatePlan }) => {
  const { t } = useI18n();
  const { stack, authProvider } = useContext(RootContext);
  const getFwUpdCfgApiCall = useGetFwUpdCfgApiCall({
    stack,
    authProvider,
    deviceUuid: device?.identity?.deviceUuid
  });

  const content = useMemo(() => {
    if (getFwUpdCfgApiCall.error) {
      return (
        <ErrorWidget message={t('home.myPrintersCard.error.general.message')} />
      );
    }
    return (
      <FirmwareUpdateSettings
        fwUpdCfg={getFwUpdCfgApiCall.data}
        device={device}
        subscription={subscription}
        subscriptionUpdatePlan={subscriptionUpdatePlan}
      />
    );
  }, [
    getFwUpdCfgApiCall.data,
    getFwUpdCfgApiCall.error,
    device,
    subscription,
    subscriptionUpdatePlan,
    t
  ]);

  if (
    getFwUpdCfgApiCall.pending ||
    (getFwUpdCfgApiCall.success &&
      getFwUpdCfgApiCall.data?.availableDeviceUpdateTypes?.length === 0)
  ) {
    return null;
  }

  return (
    <PrinterAccordion
      id="printer-firmware-update-settings"
      headerText={t('myPrinters.fwUpdate.header')}
      content={content}
      dataTestId="firmware-upd-pp"
    />
  );
};

FirmwareUpdate.defaultProps = {};

FirmwareUpdate.propTypes = {
  device: PropTypes.shape({
    identity: PropTypes.shape({
      bizModel: PropTypes.string,
      supplyDelivery: PropTypes.string,
      deviceUuid: PropTypes.string
    }),
    supplies: PropTypes.shape({
      consumables: PropTypes.arrayOf(
        PropTypes.shape({
          consumablePlatform: PropTypes.string
        })
      )
    })
  }).isRequired,
  subscription: PropTypes.string.isRequired,
  subscriptionUpdatePlan: PropTypes.string.isRequired
};

export default FirmwareUpdate;
