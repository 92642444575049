import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { RadioButtons, RadioButton, Button, useToast } from '@veneer/core';
import {
  StatusItem,
  StatusItemCard,
  StatusItemDescription,
  StatusItemGroup,
  StatusItemLabel,
  StatusItemMessage,
  StatusItemTitle
} from './styles';
import { RootContext, useI18n } from '@jarvis/react-portal-addons';
import {
  STATUS,
  PRINTER_BIZ_MODEL,
  SupplyDelivery,
  SUPPLY_TYPES
} from '../../utils/statusDictionary';
import useSetFwUpdCfgApiCall from '../../hooks/useSetFwUpdCfgApiCall';
import { publishButtonClickedEvent } from 'src/utils/analytics';
import { ColorCodes } from '../Supplies/constants';

const FirmwareUpdateSettings = ({
  fwUpdCfg,
  device,
  subscription,
  subscriptionUpdatePlan
}) => {
  const { stack, authProvider, analytics } = useContext(RootContext);
  const setFwUpdCfgApiCall = useSetFwUpdCfgApiCall({
    stack,
    authProvider
  });

  const analyticsMetadata = useMemo(
    () => ({
      associatedDeviceUuid: device?.identity?.deviceUuid,
      associatedDeviceProductNumber: device?.identity?.makeAndModel?.number
    }),
    [device?.identity?.deviceUuid, device?.identity?.makeAndModel?.number]
  );

  const { addToast } = useToast();
  const { t } = useI18n();
  const FirmwareUpdateType = {
    auto: 'auto',
    notify: 'notify',
    off: 'off'
  };

  const [autoUpdateOption, setAutoUpdateOption] = useState('auto');
  const [keyBody, setKeyBody] = useState(
    'myPrinters.fwUpdate.settings.body-chip'
  );
  const [keyAuto, setKeyAuto] = useState(
    'myPrinters.fwUpdate.settings.option.auto-recommended'
  );
  const [autoEnabled, setAutoEnabled] = useState(false);
  const [notifyEnabled, setNotifyEnabled] = useState(false);
  const [offEnabled, setOffEnabled] = useState(false);
  const [applyEnabled, setApplyEnabled] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showSyncNote, setShowSyncNote] = useState(false);
  const [showApply, setShowApply] = useState(true);
  const [showAuto, setShowAuto] = useState(true);
  const [showNotify, setShowNotify] = useState(true);
  const [showOff, setShowOff] = useState(true);

  const setFirmwareUpdateType = () => {
    setFwUpdCfgApiCall.makeApiCall({
      deviceUuid: device?.identity?.deviceUuid,
      updateType: autoUpdateOption
    });
  };

  useEffect(() => {
    if (setFwUpdCfgApiCall.success) {
      addToast({
        id: 'set-firmware-update-type-success-toast',
        type: 'positive',
        text: t('myPrinters.fwUpdate.settings.toast.success')
      });
    } else if (setFwUpdCfgApiCall.error) {
      addToast({
        id: 'set-firmware-update-type-failure-toast',
        type: 'negative',
        text: t('myPrinters.fwUpdate.settings.toast.failure')
      });
    }
  }, [setFwUpdCfgApiCall.success, setFwUpdCfgApiCall.error, addToast, t]);

  useEffect(() => {
    const isHpPlus = device?.identity?.bizModel === PRINTER_BIZ_MODEL.HP_PLUS;
    const isInstantInk = subscription === STATUS.SUBSCRIPTION.SUBSCRIBED;
    const isTank = device?.identity?.supplyDelivery === SupplyDelivery.tank;
    let isIph = false;
    if (device?.useNewIphLogic) {
      const supplies = [
        ...device?.identity?.supplySlots,
        ...device?.supplies?.consumables
      ];
      isIph =
        !isTank &&
        device?.identity?.supplyType === SUPPLY_TYPES.INK &&
        supplies.some(
          (supplyState) =>
            supplyState.colorCode?.toUpperCase() === ColorCodes.CMY
        );
    } else {
      isIph = device?.supplies?.consumables?.some((supplyState) =>
        ['iph', 'cissIph'].includes(supplyState.consumablePlatform)
      );
    }

    let canShowSyncNote = true;
    if (device?.useNewSyncNoteLogic) {
      // can show sync note only if Flex (not hp+) and Trade (not II and not PaaS)
      canShowSyncNote =
        !isHpPlus &&
        !isInstantInk &&
        subscriptionUpdatePlan !== STATUS.UPDATE_PLAN.PRINT_AS_A_SERVICE;
    }

    const {
      availableDeviceUpdateTypes: availableUpdateTypes = [],
      fwUpdateConfiguration: { updateType = FirmwareUpdateType.auto },
      hasShadowSyncCapabilities = false
    } = fwUpdCfg || { fwUpdateConfiguration: {} };

    const options = availableUpdateTypes.length > 1;
    const auto = availableUpdateTypes.includes(FirmwareUpdateType.auto);
    const notify = availableUpdateTypes.includes(FirmwareUpdateType.notify);
    const off = availableUpdateTypes.includes(FirmwareUpdateType.off);

    setShowSyncNote(canShowSyncNote && !hasShadowSyncCapabilities);
    setShowOptions(options);
    setAutoEnabled(auto);
    setNotifyEnabled(notify);
    setOffEnabled(off);
    setAutoUpdateOption(updateType);

    if (isHpPlus || isInstantInk) {
      setKeyAuto('myPrinters.fwUpdate.settings.option.auto-required');
      setShowApply(options);
    }
    if (isIph) {
      setKeyBody('myPrinters.fwUpdate.settings.body-circuitry');
    }
    if (isInstantInk) {
      setKeyBody('myPrinters.fwUpdate.settings.body-chip-auto');
      if (isIph) {
        setKeyBody('myPrinters.fwUpdate.settings.body-circuitry-auto');
      }
    }
    if (isTank) {
      setKeyBody('myPrinters.fwUpdate.settings.body-tank');
    }
    if (isHpPlus) {
      setKeyBody('myPrinters.fwUpdate.settings.body-hp-plus');
      setShowAuto(auto);
      setShowNotify(notify);
      setShowOff(off);
    }
  }, [
    FirmwareUpdateType.auto,
    FirmwareUpdateType.notify,
    FirmwareUpdateType.off,
    device,
    fwUpdCfg,
    subscription,
    subscriptionUpdatePlan
  ]);

  const onChangeAutoUpdateOption = (_e, value) => {
    setAutoUpdateOption(value);
    setApplyEnabled(
      value !==
        (fwUpdCfg?.fwUpdateConfiguration?.updateType || FirmwareUpdateType.auto)
    );
  };

  const controlNameEventMap = {
    auto: 'ApplyAutoUpdate',
    notify: 'ApplyNotifyWhenAvailability',
    off: 'ApplyDoNotCheck'
  };

  const printerProperties =
    `connection=${device.status?.connectionState}` +
    `&security=${device.securityAssessmentResult}` +
    `&status=${device.printerHealth}`;

  const onClickApply = () => {
    setFirmwareUpdateType();
    publishButtonClickedEvent(
      analytics,
      controlNameEventMap[autoUpdateOption],
      device?.identity?.makeAndModel.name,
      'FirmwareUpdate',
      analyticsMetadata,
      printerProperties
    );
  };

  const radioButtons = useMemo(
    () => [
      showAuto && (
        <RadioButton
          data-testid="firmware-auto-update-radio-pp"
          id="autoUpdate-auto"
          value={FirmwareUpdateType.auto}
          label={
            <span className="label-small">
              <StatusItemLabel data-testid="firmware-auto-update-title-pp">
                {t(keyAuto)}
              </StatusItemLabel>
              <StatusItemDescription data-testid="firmware-auto-update-description-pp">
                {t('myPrinters.fwUpdate.settings.option.auto-description')}
              </StatusItemDescription>
            </span>
          }
          disabled={!autoEnabled}
        />
      ),
      showNotify && (
        <RadioButton
          data-testid="firmware-notify-availability-radio-pp"
          id="autoUpdate-notify"
          value={FirmwareUpdateType.notify}
          label={
            <span className="label-small">
              <StatusItemLabel data-testid="firmware-notify-availability-title-pp">
                {t('myPrinters.fwUpdate.settings.option.notify')}
              </StatusItemLabel>
              <StatusItemDescription data-testid="firmware-notify-availability-description-pp">
                {t('myPrinters.fwUpdate.settings.option.notify-description')}
              </StatusItemDescription>
            </span>
          }
          disabled={!notifyEnabled}
        />
      ),
      showOff && (
        <RadioButton
          data-testid="firmware-dont-check-radio-pp"
          id="autoUpdate-off"
          value={FirmwareUpdateType.off}
          label={
            <span className="label-small">
              <StatusItemLabel data-testid="firmware-dont-check-title-pp">
                {t('myPrinters.fwUpdate.settings.option.off')}
              </StatusItemLabel>
              <StatusItemDescription data-testid="firmware-dont-check-description-pp">
                {t('myPrinters.fwUpdate.settings.option.off-description')}
              </StatusItemDescription>
            </span>
          }
          disabled={!offEnabled}
        />
      )
    ],
    [
      FirmwareUpdateType.auto,
      FirmwareUpdateType.notify,
      FirmwareUpdateType.off,
      autoEnabled,
      keyAuto,
      notifyEnabled,
      offEnabled,
      showAuto,
      showNotify,
      showOff,
      t
    ]
  );

  return (
    <StatusItemCard
      content={
        <>
          <StatusItemTitle
            className="subtitle-regular"
            data-testid="firmware-settings-title-pp"
          >
            {t('myPrinters.fwUpdate.settings.header')}
          </StatusItemTitle>
          <StatusItemMessage
            data-testid="firmware-settings-description-pp"
            className="caption"
            dangerouslySetInnerHTML={{
              __html: t(keyBody, {
                link: '<a data-testid="firmware-settings-link-pp" href="http://www.hp.com/learn/ds" target="_blank">www.hp.com/learn/ds</a>',
                lineBreak: '<br />',
                interpolation: { escapeValue: false }
              })
            }}
          />
          {showSyncNote && (
            <StatusItemMessage className="caption spaced">
              {t('myPrinters.fwUpdate.settings.syncNote')}
            </StatusItemMessage>
          )}
          {showOptions && (
            <StatusItemMessage className="caption spaced">
              {t('myPrinters.fwUpdate.settings.option.header')}
            </StatusItemMessage>
          )}
          <StatusItemGroup
            className="spaced"
            data-testid="firmware-settings-options-pp"
          >
            <RadioButtons
              alignment="vertical"
              onChange={onChangeAutoUpdateOption}
              value={autoUpdateOption}
              name="autoUpdate"
              id="autoUpdate"
            >
              {radioButtons.filter(Boolean)}
            </RadioButtons>
          </StatusItemGroup>
          {showApply && (
            <StatusItem>
              <StatusItemGroup>
                <Button
                  small
                  onClick={onClickApply}
                  className="label"
                  loading={setFwUpdCfgApiCall.pending}
                  disabled={!applyEnabled}
                  data-testid="firmware-apply-settings-btn-pp"
                >
                  {t('myPrinters.fwUpdate.settings.apply')}
                </Button>
              </StatusItemGroup>
            </StatusItem>
          )}
        </>
      }
    />
  );
};

FirmwareUpdateSettings.defaultProps = {};

FirmwareUpdateSettings.propTypes = {
  fwUpdCfg: PropTypes.shape({
    availableDeviceUpdateTypes: PropTypes.arrayOf(PropTypes.string),
    fwUpdateConfiguration: PropTypes.shape({
      updateType: PropTypes.string
    }),
    hasShadowSyncCapabilities: PropTypes.bool
  }),
  device: PropTypes.shape({
    identity: PropTypes.shape({
      bizModel: PropTypes.string,
      supplyDelivery: PropTypes.string,
      deviceUuid: PropTypes.string
    }),
    supplies: PropTypes.shape({
      consumables: PropTypes.arrayOf(
        PropTypes.shape({
          consumablePlatform: PropTypes.string
        })
      )
    })
  }).isRequired,
  subscription: PropTypes.string.isRequired,
  subscriptionUpdatePlan: PropTypes.string.isRequired
};

export default FirmwareUpdateSettings;
